<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
			ref="list"
        >
			<template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加品牌</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <span v-if="data.text=='1'">有效</span>
                <span v-else-if="data.text=='-1'">无效</span>
            </template>
            <template slot="operation" slot-scope="data">
                <span>
                    <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                </span>
                <a-divider type="vertical" />
                <span>
                    <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
                </span>
            </template>
        </TableList>
		<EditPop @handleSubmit="submit" :form_data="form_data" :visible.sync="visible">
		</EditPop>
    </div>
</template>

<script>
import TableList from '@/components/TableList'
import { getBrandList,saveBrand ,delBrand} from '@/api/goods'
import EditPop from "@/components/EditPop";
const columns = [
    {
        title: "品牌名称",
        dataIndex: "brand_name",
    },
    {
        title: "品牌标识",
        dataIndex: "brand_value",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "备注",
        width: "300px",
        dataIndex: "remark",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    components: {
		TableList,
		EditPop
    },
    data() {
        return {
            get_table_list: getBrandList,
            columns,
            brand_data:[],
            form_data_seo: {
                list: [
					
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: '品牌名称',
                        options: {

                        }
                    },
                    {
                        type: "select",
                        name: "status",
                        title: "状态",
                        options: {},
                        list: [
                            {
                                "key":1,
                                "value":"有效",
                            },
                            {
                                "key":-1,
                                "value":"无效"
                            }
                        ],
                    },
                ],
                ...this.$config.form_data_seo
			},
			form_data:{
				title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: 'text',
                        name: 'brand_name',
                        title: '品牌名称',
                        options: {},
					},
					{
                        type: 'text',
                        name: 'brand_value',
                        title: '品牌标识',
                        options: {},
					},
                    {
                        type: 'radio',
                        name: 'status',
                        title: '状态',
                        list: [
                            {
                                key: 1,
                                value: "有效"
                            },
                            {
                                key: -1,
                                value: "无效"
                            }
                        ],
                        options: {},
                    },
                    {
                        type: 'textarea',
                        name: 'remark',
                        title: '备注',
                        options: {},
                    },
                ]
			},
			visible:false,
        }
	},
	methods:{
		add(){
			this.form_data.title = "添加"
			this.form_data.list.forEach(item => {
                item.options.initialValue = '';
			})
			this.visible = true;
		},
		edit(record){
			this.form_data.title = "编辑"
			let data = record
            this.form_data.list.forEach(item => {
                item.options.initialValue = data[item.name];
            })
            this.visible = true;
		},
		submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            saveBrand({
                data: {
                    ...data,
				},
				info:true
            }).then(res => {
				this.visible = false;
				this.$refs.list.get_list();
            })

        },
		del(record) {
            delBrand({
                data: {
                    id: record.id
				},
				info:true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
	}
}
</script>
<style type="text/css" scoped>
	.slot_test {
		display: flex;
	}
	.barnd_name{
		flex: 2;
	}
	.barnd_rt{
		flex: 1;
		margin-left: 10px;
	}
</style>